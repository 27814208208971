

import { createAsyncThunk } from '@reduxjs/toolkit';
import Swal from 'sweetalert2';
import { fetchConToken, handleError } from '../../helpers/fetch';
import { loadStatusNodeText, testFlagNode } from '../../helpers/nodes/statusNodes';
import { purpleTheme } from '../../material-ui/theme';
import { 
  nodesLoad, 
  nodesActive, 
  nodesUpdate, 
  nodesRemoveActiveItem, 
  nodesActiveItem 
} from './nodesReducer';

const fields = [
  'id',
  'contract_id',
  'latitude',
  'longitude',
  'name',
  'alarm',
  'new',
  'last_gateway_connection',
  'status_flag',
  'serial_number',
  'icc',
  'firmware_version',
  'ip',
  'eui',
  'current_gateway_number',
  'send_dimming',
  'send_exception',
  'send_node_config',
  'send_holiday',
  'send_node_restart'
];

export const startNodesLoad = createAsyncThunk(
  'nodes/startNodesLoad',
  async (contractId, { dispatch }) => {
    if (contractId) {
      try {
        const result = await fetchConToken(`contract/${contractId}/nodes?fields=${fields.toString()}`, undefined, 'GET');
        if (!result.ok) {
          throw result.status;
        }
        const nodes = await result.json();
        dispatch(nodesLoad(nodes));
      } catch (err) {
        handleError(err, dispatch);
      }
    }
  }
);


export const startNodesUpdate = createAsyncThunk(
  'nodes/startNodesUpdate',
  async (data, { dispatch, getState }) => {
    console.log("Entra en actions")
    const { contracts: { activeContract }, nodes: { nodes } } = getState();

    if (typeof data !== 'object' || !activeContract || !nodes) {
      return;
    }

    Swal.fire({
      title: `${'Loading'}...`,
      text: 'Please wait',
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      console.log("Peticion patch para formularia en el mapa")
      const result = await fetchConToken(`contract/${activeContract.id}/nodes/${data.id}`, { ...data }, 'PATCH');

      if (!result.ok && result.status !== 405) {
        throw result.status;
      }

      //const resultData = await result.json();
      const node = nodes.find(e => e.id === data.id);

      if (result.status === 405) {
        Swal.fire({
          titleText: 'Error',
          text: loadStatusNodeText(1, node),
          icon: 'warning',
          confirmButtonColor: purpleTheme.palette.primary.main,
        });
      } else {
        dispatch(nodesUpdate([{
          ...node,
          ...data
        }]));
        Swal.fire({
          titleText: 'Saved',
          text: ("¡${node?.name} actualizado!", { name: node?.name }),
          icon: 'success',
          confirmButtonColor: purpleTheme.palette.primary.main,
        });
      }
    } catch (err) {
      handleError(err, dispatch);
    }
  }
);

export const startNodesMultipleUpdate = createAsyncThunk(
  'nodes/startNodesMultipleUpdate',
  async ({ devices, status_flag = 1 }, { dispatch, getState }) => {
    const { contracts: { activeContract } } = getState();

    if (activeContract && activeContract.id && Array.isArray(devices) && devices.length > 0) {
      Swal.fire({
        title: `${'Loading'}...`,
        text: 'Please wait',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        const requests = [
          fetchConToken(`contract/${activeContract.id}/nodes?fields=${fields.toString()}`),
          fetchConToken(`contract/${activeContract.id}/nodes?status_flag=${status_flag}`, devices, 'PATCH'),
        ];

        const responses = await Promise.all(requests);
        responses.forEach(e => {
          if (!e.ok && e.status !== 405) {
            throw e.status;
          }
        });

        const [nodes, results] = await Promise.all(responses.map(r => r.json()));
        const nodes_no_ok = nodes.filter(e => !testFlagNode(e.status_flag));
        const devices_no_ok = nodes_no_ok.filter(e => devices.find(item => item.id === e.id));

        dispatch(startNodesLoad());

        const text = `There is updates ${results.changedRows} of ${devices.length} selected devices. 
          ${(devices_no_ok.length > 0) ?
              `The following ${'Luminaires'} cannot be updated at the moment, please try again later: ${devices_no_ok.map(e => loadStatusNodeText(e.status_flag, e)).join(', ')}` :
              ''
          }`;

        Swal.fire({
          titleText: (devices_no_ok.length > 0) ? 'Atención' : 'Saved',
          text,
          icon: (devices_no_ok.length > 0) ? 'warning' : 'success',
          confirmButtonColor: purpleTheme.palette.primary.main,
        });

      } catch (err) {
        handleError(err, dispatch);
      }
    }
  }
);

export const startNodesActiveItem = createAsyncThunk(
  'nodes/startNodesActiveItem',
  async (id, { dispatch, getState }) => {
    const { contracts: { activeContract } } = getState();

    if (activeContract && activeContract.id && id) {
      try {
        const result = await fetchConToken(`contract/${activeContract.id}/nodes/${id}`);
        if (!result.ok) {
          throw result.status;
        }
        const node = await result.json();
        dispatch(nodesActiveItem(node.length ? node[0] : null));
      } catch (err) {
        handleError(err, dispatch);
      }
    }
  }
);

export const startNodesDelete = createAsyncThunk(
  'nodes/startNodesDelete',
  async (id, { dispatch, getState }) => {
    const { contracts: { activeContract }, nodes: { nodes } } = getState();

    if (activeContract && activeContract.id && id) {
      Swal.fire({
        title: `${'Loading'}...`,
        text: 'Please wait',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        const result = await fetchConToken(`contract/${activeContract.id}/nodes/${id}`, null, 'DELETE');
        if (!result.ok) {
          throw result.status;
        }
        await result.json();

        Swal.fire({
          titleText: 'Saved',
          text: ("¡{{name}} update!", { name: nodes.find(e => parseInt(e.id) === parseInt(id))?.name }),
          icon: 'success',
          confirmButtonColor: purpleTheme.palette.primary.main,
        });

        dispatch(nodesRemoveActiveItem());
        dispatch(nodesActive([{ id: parseInt(id) }]));
        dispatch(nodesLoad(nodes.filter(e => parseInt(e.id) !== parseInt(id))));
      } catch (err) {
        handleError(err, dispatch);
      }
    }
  }
);

