



import React, { useState, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  CircularProgress,
  Tooltip,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Divider,
  TextField,
  Typography
} from '@mui/material';
import LocationCityRoundedIcon from '@mui/icons-material/LocationCityRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useSelector } from 'react-redux';
import theme from '../../../material-ui/theme';
import { ContractRow } from './ContractRow/ContractRow';
import { useFetch } from '../../../hooks/useFetch';

export const DeviceTable = () => {
  const activeContract = useSelector((state) => state.contracts.activeContract);
  const { data, loading } = useFetch(`contracts/failandalarms`);
  console.log("DATA: ", data)


  const [sortBy, setSortBy] = useState('panels');
  const [searchQuery, setSearchQuery] = useState('');

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  const filteredContracts = useMemo(() => {
    if (!data) return [];
    return data.filter((contract) =>
      contract.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [data, searchQuery]);

  const sortedContracts = useMemo(() => {
    if (!filteredContracts) return [];

    return [...filteredContracts].sort((a, b) => {
      if (sortBy === 'nodes') {
        return b.fails_nodes.fails24.length - a.fails_nodes.fails24.length;
      } else if (sortBy === 'panels') {
        return b.fails_cm.fails24.length - a.fails_cm.fails24.length;
      } else if (sortBy === 'gateways') {
        return b.fails_gateways.fails24.length - a.fails_gateways.fails24.length;
      }
      return 0;
    });
  }, [filteredContracts, sortBy]);

  return (
    <>
      {(loading || !activeContract) ? (
        <CircularProgress color="primary" />
      ) : (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>

            <TextField
              label="Buscar contrato"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{ marginRight: '16px' }}
            />
            <FormControl variant="outlined" style={{ width: '160px', height: '40px' }}>
              <InputLabel id="sort-by-label">Ordenar por</InputLabel>
              <Select
                labelId="sort-by-label"
                value={sortBy}
                onChange={handleSortChange}
                label="Ordenar por"
              >
                <MenuItem value="panels">Centros de mando</MenuItem>
                <MenuItem value="nodes">Nodos</MenuItem>
                <MenuItem value="gateways">Gateways</MenuItem>
              </Select>
            </FormControl>
          </div>

          <Divider />

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell rowSpan={2} style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '1.05rem', borderRight: '1px solid black' }}>
                    <LocationCityRoundedIcon style={{ fontSize: '1.05rem', color: 'blue', marginRight: '0.5rem' }} />
                    Contrato
                  </TableCell>
                  <TableCell colSpan={3} style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.05rem', borderRight: '1px solid black' }}>
                    <ErrorOutlineRoundedIcon style={{ fontSize: '1.05rem', color: 'red', marginRight: '0.5rem' }} />
                    Fallos de comunicación <Typography> 72H | 48H | 24H | Total </Typography>
                  </TableCell>
                  <TableCell colSpan={3} style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.05rem' }}>
                    <WarningRoundedIcon style={{ fontSize: '1.05rem', color: 'red', marginRight: '0.5rem' }} />
                    Alarmas
                  </TableCell>
                </TableRow>
                <TableRow>

                  <TableCell style={{ fontWeight: 'bold', fontSize: '1.05rem', textAlign: 'left', alignItems: 'center' }}>
                    <Tooltip title={'Centros de Mando'}>
                      <Avatar
                        src={theme.devicesIcons.panel.avatar}
                        alt="Panel Icon"
                        style={{ width: '24px', height: '24px', marginRight: '0.5rem' }}
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '1.05rem', textAlign: 'left' }}>
                    <Tooltip title={'Nodos'}>
                      <Avatar
                        src={theme.devicesIcons.node.avatar}
                        alt="Node Icon"
                        style={{ width: '24px', height: '24px', marginRight: '0.5rem' }}
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '1.05rem', textAlign: 'left', borderRight: '1px solid black' }}>
                    <Tooltip title={'Gateways'}>
                      <Avatar
                        src={theme.devicesIcons.gateway.avatar}
                        alt="Gateway Icon"
                        style={{ width: '24px', height: '24px', marginRight: '0.5rem' }}
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '1.05rem', textAlign: 'left', alignItems: 'center' }}>
                    <Tooltip title={'Centros de Mando'}>
                      <Avatar
                        src={theme.devicesIcons.panel.avatar}
                        alt="Panel Icon"
                        style={{ width: '24px', height: '24px', marginRight: '0.5rem' }}
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '1.05rem', textAlign: 'left' }}>
                    <Tooltip title={'Nodos'}>
                      <Avatar
                        src={theme.devicesIcons.node.avatar}
                        alt="Node Icon"
                        style={{ width: '24px', height: '24px', marginRight: '0.5rem' }}
                      />
                    </Tooltip>
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(sortedContracts) && sortedContracts.length > 0 ? (
                  sortedContracts.map((contract) => (
                    <ContractRow key={contract.id} contract={contract} activeContractId={activeContract?.id} />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                      No se encontraron contratos
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </>
  );
};




