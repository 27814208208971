

import moment from 'moment';

export const thereIsAFail = (lastConnection, hoursToLimit) => {
    const deviceDate = moment(lastConnection);
    const limitDate = moment().add(-hoursToLimit, 'hours');
    if(deviceDate < limitDate) {
        return true;
    }
    return false;
}


export const rateColorMeasures = (value, total, hours = undefined, isFail = false, previousValue = 0) => {
  let percentage = 0;

  if (total !== 0) {
    percentage = (value / total) * 100;
  }

  let color = '#f2f4f4'; //color por defecto

  if (isFail) {
    //logica para fallos de comunicacion
    if (value > previousValue) {
      if (previousValue === 0) {
        color = '#f2f4f4';
      } else {
        color = '#e74c3c';
      } 
    } else {
      color = '#f2f4f4'; 
    }
  } else {
    if (hours === undefined) {
      if (value !== 0 && percentage >= 0 && percentage <= 10) {
        color = '#f9e79f';
      } else if (percentage > 10 && percentage <= 20) {
        color = '#f39c12';
      } else if (percentage > 20) {
        color = '#f87962';
      }
    }
  }

  return { value, color, percentage };
};