import React, { useEffect, useMemo, useRef } from 'react'
import L from 'leaflet'
import theme from '../../../../../material-ui/theme'
import { Marker, Popup } from 'react-leaflet'
import { Card, CardContent, CardHeader } from '@mui/material'
import { NodesOptionsMenu } from '../../../../common/Options/NodesOptionsMenu/NodesOptionsMenu'
import DynamicForm from '../../../../common/DynamicForm/DynamicForm'
import { useDispatch, useSelector } from 'react-redux'
import { nodesActive } from '../../../../../reducers/nodes/nodesReducer'
import { startNodesUpdate } from '../../../../../reducers/nodes/nodesActions'

const NodesMarker = React.memo(
  ({
    possition,
    data,
    active,
    fail,
    alarm,
    newDevice,
    gatewayNumber,
    firmwareVersion,
    onRender
  }) => {
    const markerRef = useRef(null)
    const dispatch = useDispatch()
    const onlyActive = useSelector(state => state.map.filters.active)
    const onlyFails = useSelector(state => state.map.filters.fail)
    const onlyAlarm = useSelector(state => state.map.filters.alarm)
    const onlyNew = useSelector(state => state.map.filters.new)
    const current_gateway_number = useSelector(state => state.map.filters.current_gateway_number)
    const firmwareIcons = useSelector(state => state.map.filters.firmwareIcons)
    const gatewayIcons = useSelector(state => state.map.filters.gatewayIcons)

    const handleRightClick = e => {
      if (markerRef.current) {
        markerRef.current.openPopup()
      }
    }
    const handleLeftClick = e => {
      dispatch(nodesActive([{ id: data.id }]))
      if (markerRef.current) {
        markerRef.current.closePopup()
      }
    }

    const handleFormSubmit = (formData) => {
      console.log("Llega al manejador")
      dispatch(startNodesUpdate({ id: data.id, ...formData }));
    };

    const currentIcon = useMemo(() => {
      const getAlarmOrNewIcon = () => {
        if (alarm) {
          return active
            ? theme.devicesIcons.node.active.alarm
            : theme.devicesIcons.node.alarm
        } else {
          return active
            ? newDevice
              ? theme.devicesIcons.node.active.new
              : theme.devicesIcons.node.active.main
            : newDevice
            ? theme.devicesIcons.node.new
            : theme.devicesIcons.node.main
        }
      }

      if(firmwareIcons.length){
        return active? firmwareIcons.find(e => e.version === firmwareVersion)?.activeIcon : firmwareIcons.find(e => e.version === firmwareVersion)?.icon
      } else if(gatewayIcons.length){
        return active? gatewayIcons.find(e => e.gateway_number === gatewayNumber)?.activeIcon : gatewayIcons.find(e => e.gateway_number === gatewayNumber)?.icon
      } else if (!fail) {
        return getAlarmOrNewIcon()
      } else {
        return active
          ? theme.devicesIcons.node.active.fail
          : theme.devicesIcons.node.fail
      }
    }, [active, fail, alarm, newDevice, firmwareIcons, firmwareVersion, gatewayNumber, gatewayIcons])

    const icon = L.icon({
      iconUrl: currentIcon,
      iconSize: [active ? 20 : 12, active ? 24 : 16] // Tamaño del icono (ajústalo al tamaño real de tu icono)
    })

    useEffect(() => {
      if (onRender) {
        onRender() // Llama a la función cuando el marcador se ha renderizado
      }
    }, [onRender])

    if(firmwareIcons.length && !firmwareIcons.find(e => e.version === firmwareVersion)){
      return null
    }
    if(gatewayIcons.length && !gatewayIcons.find(e => e.gateway_number === gatewayNumber)){
      return null
    }
    if (onlyActive && !active) {
      return null
    }
    if (onlyFails && !fail) {
      return null
    }
    if (onlyAlarm && !alarm) {
      return null
    }
    if (onlyNew && !newDevice) {
      return null
    } 
    if (parseInt(current_gateway_number) !== -1 && parseInt(current_gateway_number) !== parseInt(gatewayNumber)){
      return null
    }
    return (
      <>
        <Marker
          id={data.id}
          type='nodes'
          position={possition}
          icon={icon} // Icono opcional
          ref={markerRef}
          eventHandlers={{
            contextmenu: handleRightClick, // Detecta el clic derecho
            click: handleLeftClick
          }}
        >
          <Popup maxWidth='none' autoClose={false} closeOnClick={false}>
            <Card elevation={0}>
              <CardHeader title={data.name} action={<NodesOptionsMenu id={data.id}/>} />
              <CardContent>
                <DynamicForm
                  fields={[
                    {
                      name: 'name',
                      label: 'Nombre del equipo',
                      type: 'text',
                      value: data.name
                    },
                    {
                      name: 'eui',
                      label: 'E.U.I.',
                      type: 'text',
                      value: data.eui,
                      readOnly: true
                    },
                    {
                      name: 'icc',
                      label: 'I.C.C.',
                      type: 'text',
                      value: data.icc,
                      readOnly: true
                    },
                    {
                      name: 'ip',
                      label: 'I.P.',
                      type: 'text',
                      value: data.ip,
                      readOnly: true
                    },
                    {
                      name: 'last_gateway_connection',
                      label: 'Última conexión',
                      type: 'datetime',
                      value: data.last_gateway_connection,
                      readOnly: true
                    },
                    {
                      name: 'current_gateway_number',
                      label: 'Número de GW',
                      type: 'number',
                      value: data.current_gateway_number,
                      //readOnly: true
                      //Faltan mas modificaciones sobre este formulario
                    },
                    {
                      name: 'status_flag',
                      label: 'Flag modificado',
                      type: 'checkbox',
                      value: data.status_flag,
                      readOnly: true
                    },
                    {
                      name: 'serial_number',
                      label: 'Número de serie',
                      type: 'number',
                      value: data.serial_number,
                      readOnly: true
                    },
                    {
                      name: 'firmware_version',
                      label: 'Versión de Firmware',
                      type: 'number',
                      value: data.firmware_version,
                      readOnly: true
                    },
                    {
                      name: 'alarm',
                      label: 'Alarma',
                      type: 'checkbox',
                      value: data.alarm,
                      readOnly: true
                    },
                    {
                      name: 'new',
                      label: 'Nuevo equipo',
                      type: 'checkbox',
                      value: data.new,
                      readOnly: true
                    }
                  ]}
                  handle={handleFormSubmit}
                />
              </CardContent>
            </Card>
          </Popup>
        </Marker>
      </>
    )
  }
)

export default NodesMarker
