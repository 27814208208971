


import React from 'react'
import { TableCell, TableRow, Tooltip, Chip, Button } from '@mui/material';
import { rateColorMeasures } from '../../../../helpers/common';
import { panelsActive, panelsRemoveActive } from '../../../../reducers/panels/panelsReducer';
import { nodesActive, nodesRemoveActive } from '../../../../reducers/nodes/nodesReducer';
import { gatewaysActive, gatewaysRemoveActive } from '../../../../reducers/gateways/gatewaysReducers';
import { useDispatch } from 'react-redux';
import { startContractsActive } from '../../../../reducers/contracts/contractsActions';

export const ContractRow = ({ contract, activeContractId }) => {

  const dispatch = useDispatch();

  const handleClick = (id) => {
    dispatch(startContractsActive(id))
  }

  const panelCount = contract.device_counts[0];
  const nodeCount = contract.device_counts[1];
  const gatewayCount = contract.device_counts[2];

  //HALF 72H
  //DOUBLE 48H
  //FAIL 24H
  /*
  const colorPanelsFail = rateColorMeasures(contract.fails_cm?.fails24?.length || 0, panelCount);
  const colorPanelsFailDouble = rateColorMeasures(contract.fails_cm?.fails48?.length || 0, panelCount);
  const colorPanelsFailHalf = rateColorMeasures(contract.fails_cm?.fails72?.length || 0, panelCount);
  const colorPanelsAlarm = rateColorMeasures(contract.alerts_cm?.length || 0, panelCount);

  const colorNodesFail = rateColorMeasures(contract.fails_nodes?.fails24?.length || 0, nodeCount);
  const colorNodesFailDouble = rateColorMeasures(contract.fails_nodes?.fails48?.length || 0, nodeCount);
  const colorNodesFailHalf = rateColorMeasures(contract.fails_nodes?.fails72?.length || 0, nodeCount);
  const colorNodesAlarm = rateColorMeasures(contract.alert_nodes?.length || 0, nodeCount);

  const colorGatewaysFail = rateColorMeasures(contract.fails_gateways?.fails24?.length || 0, gatewayCount);
  const colorGatewaysFailDouble = rateColorMeasures(contract.fails_gateways?.fails48?.length || 0, gatewayCount);
  const colorGatewaysFailHalf = rateColorMeasures(contract.fails_gateways?.fails72?.length || 0, gatewayCount);
  */

  const filteredAlertsCm = contract.alerts_cm.filter(
    (id) => !contract.fails_cm?.fails24.includes(id)
  );
  const filteredAlertsNodes = contract.alert_nodes.filter(
    (id) => !contract.fails_nodes?.fails24.includes(id)
  );

  const colorPanelsFail = rateColorMeasures(contract.fails_cm?.fails24?.length || 0, panelCount, undefined, true, contract.fails_cm?.fails48?.length || 0);
  const colorPanelsFailDouble = rateColorMeasures(contract.fails_cm?.fails48?.length || 0, panelCount, undefined, true, contract.fails_cm?.fails72?.length || 0);
  const colorPanelsFailHalf = rateColorMeasures(contract.fails_cm?.fails72?.length || 0, panelCount, undefined, true);
  const colorPanelsAlarm = rateColorMeasures(filteredAlertsCm?.length || 0, panelCount);

  const colorNodesFail = rateColorMeasures(contract.fails_nodes?.fails24?.length || 0, nodeCount, undefined, true, contract.fails_nodes?.fails48?.length || 0);
  const colorNodesFailDouble = rateColorMeasures(contract.fails_nodes?.fails48?.length || 0, nodeCount, undefined, true, contract.fails_nodes?.fails72?.length || 0);
  const colorNodesFailHalf = rateColorMeasures(contract.fails_nodes?.fails72?.length || 0, nodeCount, undefined, true);
  const colorNodesAlarm = rateColorMeasures(filteredAlertsNodes?.length || 0, nodeCount);

  const colorGatewaysFail = rateColorMeasures(contract.fails_gateways?.fails24?.length || 0, gatewayCount, undefined, true, contract.fails_gateways?.fails48?.length || 0);
  const colorGatewaysFailDouble = rateColorMeasures(contract.fails_gateways?.fails48?.length || 0, gatewayCount, undefined, true, contract.fails_gateways?.fails72?.length || 0);
  const colorGatewaysFailHalf = rateColorMeasures(contract.fails_gateways?.fails72?.length || 0, gatewayCount, undefined, true);


  const handleActivatePanels = (Ids) => {
    console.log("Lanzando accionnnnnnnnnn", Ids)
    if (Ids.length > 0 && activeContractId === contract.id) {
      dispatch(panelsRemoveActive());
      dispatch(panelsActive(Ids.map(id => ({ id }))));
      //dispatch(panelsActiveItem(Ids.map(id => ({ id }))));
    }
  };

  const handleActivateNodes = (Ids) => {
    if (Ids.length > 0 && activeContractId === contract.id) {
      dispatch(nodesRemoveActive());
      dispatch(nodesActive(Ids.map(id => ({ id }))));
    }
  };

  const handleActivateGateways = (Ids) => {
    if (Ids.length > 0 && activeContractId === contract.id) {
      dispatch(gatewaysRemoveActive());
      dispatch(gatewaysActive(Ids.map(id => ({ id }))));
    }
  };



  return (
    <TableRow selected={activeContractId === contract.id}>
      <TableCell style={{ borderRight: '1px solid black' }}>
        <Button
          onClick={() => handleClick(contract.id)}
          variant="outlined"
          disabled={activeContractId === contract.id}
          style={{
            fontWeight: 'bold',
            color: activeContractId === contract.id ? 'white' : 'black',
            backgroundColor: activeContractId === contract.id ? '#f39c12' : 'transparent',
            borderColor: activeContractId === contract.id ? '#f39c12' : 'rgba(0, 0, 0, 0.23)',
          }}
        >
          {contract.name}
        </Button>
      </TableCell>

      <TableCell>
        {panelCount > 0 ? (
          <>
            <Tooltip title={`${colorPanelsFailHalf.percentage}%`}>
              <Chip label={`${colorPanelsFailHalf.value}`}
                size='small'
                style={{ backgroundColor: colorPanelsFailHalf.color }}
                onClick={() => handleActivatePanels(contract.fails_cm.fails72)}
              />
            </Tooltip>


            <Tooltip title={`${colorPanelsFailDouble.percentage}%`}>
              <Chip
                label={`${colorPanelsFailDouble.value}`}
                size='small'
                style={{ backgroundColor: colorPanelsFailDouble.color }}
                onClick={() => handleActivatePanels(contract.fails_cm.fails48)}
              />
            </Tooltip>
            <Tooltip title={`${colorPanelsFail.percentage}%`}>
              <Chip
                label={`${colorPanelsFail.value}`}
                size='small'
                style={{ backgroundColor: colorPanelsFail.color }}
                onClick={() => handleActivatePanels(contract.fails_cm.fails24)}
              />
            </Tooltip>

            <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>
              {panelCount}
            </span>
          </>
        ) : (
          <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>-</span>
        )}
      </TableCell>

      <TableCell>
        {nodeCount > 0 ? (
          <>
            <Tooltip title={`${colorNodesFailHalf.percentage}%`}>
              <Chip size='small'
                label={`${colorNodesFailHalf.value} `}
                style={{ backgroundColor: colorNodesFailHalf.color }}
                onClick={() => handleActivateNodes(contract.fails_nodes.fails72)}
              />
            </Tooltip>


            <Tooltip title={`${colorNodesFailDouble.percentage}%`}>
              <Chip size='small'
                label={`${colorNodesFailDouble.value} `}
                style={{ backgroundColor: colorNodesFailDouble.color }}
                onClick={() => handleActivateNodes(contract.fails_nodes.fails48)}
              />
            </Tooltip>
            <Tooltip title={`${colorNodesFail.percentage}%`}>
              <Chip size='small'
                label={`${colorNodesFail.value}`}
                style={{ backgroundColor: colorNodesFail.color }}
                onClick={() => handleActivateNodes(contract.fails_nodes.fails24)}
              />
            </Tooltip>

            <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>
              {nodeCount}
            </span>
          </>
        ) : (
          <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>-</span>
        )}
      </TableCell>

      <TableCell style={{ borderRight: '1px solid black' }}>
        {nodeCount > 0 ? (
          <>
            <Tooltip title={`${colorGatewaysFailHalf.percentage}%`}>
              <Chip size='small'
                label={`${colorGatewaysFailHalf.value} `}
                style={{ backgroundColor: colorGatewaysFailHalf.color }}
                onClick={() => handleActivateGateways(contract.fails_gateways.fails72)}
              />
            </Tooltip>
            <Tooltip title={`${colorGatewaysFailDouble.percentage}%`}>
              <Chip size='small'
                label={`${colorGatewaysFailDouble.value} `}
                style={{ backgroundColor: colorGatewaysFailDouble.color }}
                onClick={() => handleActivateGateways(contract.fails_gateways.fails48)}

              />
            </Tooltip>
            <Tooltip title={`${colorGatewaysFail.percentage}%`}>
              <Chip size='small'
                label={`${colorGatewaysFail.value} `}
                style={{ backgroundColor: colorGatewaysFail.color }}
                onClick={() => handleActivateGateways(contract.fails_gateways.fails24)}
              />
            </Tooltip>

            <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>
              {gatewayCount}
            </span>
          </>
        ) : (
          <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>-</span>
        )}
      </TableCell>

      <TableCell>
        <Tooltip title={`${colorPanelsAlarm.percentage}%`}>
          <Chip size='small'
            label={`${colorPanelsAlarm.value}`}
            style={{ backgroundColor: colorPanelsAlarm.color }}
            onClick={() => handleActivatePanels(filteredAlertsCm)}
          />
        </Tooltip>
      </TableCell>

      <TableCell>
        <Tooltip title={`${colorNodesAlarm.percentage}%`}>
          <Chip size='small'
            label={`${colorNodesAlarm.value}`}
            style={{ backgroundColor: colorNodesAlarm.color }}
            onClick={() => handleActivateNodes(filteredAlertsNodes)}
          />
        </Tooltip>
      </TableCell>

    </TableRow >
  );
};



