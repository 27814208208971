import { useDispatch, useSelector } from 'react-redux'
import PropTypes from "prop-types";
import { nodesActive } from '../reducers/nodes/nodesReducer';
import { panelsActive } from '../reducers/panels/panelsReducer';
import { gatewaysActive } from '../reducers/gateways/gatewaysReducers';

const useLoadSelectedDevicesJson = (type) => {
  const activeContract = useSelector(state => state.contracts.activeContract)
  const nodes = useSelector(state => state.nodes.nodes)
  const panels = useSelector(state => state.panels.panels)
  const gateways = useSelector(state => state.gateways.gateways)

  const dispatch = useDispatch()

  const checkType = (value, type) => {
    if (typeof value === type) {
      return value
    } else {
      if (type === 'string') {
        return value.toString()
      } else {
        return parseInt(value)
      }
    }
  }

  const handleFiles = files => {
    try {
      const fileReader = new FileReader()
      fileReader.readAsText(files[0], 'UTF-8')
      fileReader.onload = async event => {
        const content = JSON.parse(event.target.result)
        if (parseInt(content.id_contract) !== parseInt(activeContract.id)) {
          return
        }
        if (content.gateways && content.gateways.length) {
          dispatch(
            gatewaysActive(
              content.gateways
                .map(e => ({ id: checkType(e.id, type) }))
                .filter(e => gateways.find(item => e.id && item.id === e.id))
            )
          )
        }
        if (content.panels && content.panels.length) {
          dispatch(
            panelsActive(
              content.panels
                .map(e => ({ id: checkType(e.id, type) }))
                .filter(e => panels.find(item => e.id && item.id === e.id))
            )
          )
        }
        if (content.nodes && content.nodes.length) {
          dispatch(
            nodesActive(
              content.nodes
                .map(e => ({ id: checkType(e.id, type) }))
                .filter(e => nodes.find(item => e.id && item.id === e.id))
            )
          )
        }
      }
    } catch (err) {
      console.error(err)
    }
  }
  return handleFiles
}

useLoadSelectedDevicesJson.propTypes = {
  type:PropTypes.oneOf(["string", "number"]).isRequired
}

export default useLoadSelectedDevicesJson

/*
import ReactFileReader from 'react-file-reader'

<ReactFileReader handleFiles={handleFiles} fileTypes={'.json'}>
<Tooltip title={t('Cargar')}>
    <IconButton>
    <PublishIcon />
    </IconButton>
</Tooltip>
</ReactFileReader>
*/
