


import React, { useEffect } from 'react';
import {
    Avatar,
    Container,
    Grid,
    Card,
    CardHeader,
    CardContent,
    Divider,
    CardActions,
    Button,
    TextField,
    FormHelperText,
    TextareaAutosize,
} from '@mui/material';
import { Save as SaveIcon, Replay as ReplayIcon } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks } from '@fortawesome/free-solid-svg-icons';
import validator from 'validator';

import { CustomTitle } from '../common/CustomTitle/CustomTitle';
import { GoBack } from '../common/GoBack/GoBack';
import { useForm } from '../../hooks/useForm';
import { uiRemoveError, uiSetError } from '../../reducers/ui/uiReducer';
import { startGatewaysCreate } from '../../reducers/gateways/gatewaysActions';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import GeoSearch from '../AdvancedMonitoringMapScreen/Map/GeoSearch/GeoSearch';

export const RegistrationsGatewaysScreen = () => {
    //const navigate = useNavigate();
    const dispatch = useDispatch();

    const activeContract = useSelector((state) => state.contracts.activeContract);
    const type = useSelector((state) => state.contracts.type);
    const onlyIp = useSelector((state) => state.contracts.onlyIp);
    const gateways = useSelector((state) => state.gateways.gateways);
    const msgError = useSelector((state) => state.ui.msgError);

    const [values, handleInputChange, reset] = useForm({
        name: '',
        longitude: '',
        latitude: '',
        address: '',
        number: '',
        location: '',
        province: '',
        description: '',
        ip: '',
        dimming_profile_id: -1,
        exception_profile_id: -1,
        tcp_port: 40001,
        gateway_number: '',
        contract_id: activeContract.id
    });

    const {
        name,
        longitude,
        latitude,
        address,
        number,
        location,
        province,
        description,
        ip,
        dimming_profile_id,
        exception_profile_id,
        tcp_port,
        gateway_number,
        contract_id
    } = values;

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isFormValid()) {
            console.log(values)
            dispatch(startGatewaysCreate(values));
            //navigate('/advanced/registrations');
        }
    };

    const handleReset = (e) => {
        e.preventDefault();
        reset({
            name: '',
            longitude: '',
            latitude: '',
            address: '',
            number: '',
            location: '',
            province: '',
            description: '',
            ip: '',
            dimming_profile_id: null,
            exception_profile_id: null,
            tcp_port: '',
            gateway_number: '',
            contract_id: activeContract.id
        });
    };

    const LocationMarker = () => {
        useMapEvents({
            click(e) {
                const { lat, lng } = e.latlng;
                reset({
                    ...values,
                    latitude: parseFloat(lat.toFixed(6)).toString(),
                    longitude: parseFloat(lng.toFixed(6)).toString(),
                });
            },
        });

        return latitude && longitude ? (
            <Marker position={[parseFloat(latitude), parseFloat(longitude)]}>
                <Popup>
                    Latitud: {latitude}, Longitud: {longitude}
                </Popup>
            </Marker>
        ) : null;
    };

    const isFormValid = () => {
        if (!name || gateways.find((e) => e.name === name)) {
            dispatch(uiSetError(`El campo "Nombre" es incorrecto o ya está en uso.`));
            return false;
        }
        
        if (!validator.isLatLong(`${latitude}, ${longitude}`)) {
            dispatch(uiSetError('Las coordenadas de latitud y longitud no son válidas.'));
            return false;
        }
        if (!validator.isPort(tcp_port.toString())) {
            dispatch(uiSetError('El puerto TCP no es válido.'));
            return false;
        }
        if (gateways.find((e) => e.gateway_number === gateway_number)) {
            dispatch(uiSetError(`El número de gateway ${gateway_number} ya está en uso.`));
            return false;
        }
        dispatch(uiRemoveError());
        return true;
    };

    useEffect(() => {
        return () => {
            dispatch(uiRemoveError());
        };
    }, [dispatch]);

    const disabledSaveButton =
        !(type && type.includes('P2P') && !onlyIp) && gateways.length >= activeContract?.number_of_gateways;

    return (
        <Container maxWidth={false} sx={{ p: 2 }}>
            <Grid container spacing={1}>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                    <GoBack />
                    <CustomTitle
                        title={`Dar de alta equipos manualmente ~ Gateways`}
                        icon={<Avatar> <FontAwesomeIcon icon={faTasks} /> </Avatar>}
                    />
                </Grid>

                <Grid container justifyContent="center" spacing={3} sx={{ my: 2 }}>
                    <Grid item xs={12} sm={10} lg={10}>
                        <Card>
                            <CardHeader
                                avatar={<Avatar />}
                                title="Nuevo Gateway"
                            />
                            <form onSubmit={handleSubmit} onReset={handleReset}>
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Nombre"
                                                name="name"
                                                value={name}
                                                onChange={handleInputChange}
                                                size="small"
                                                required
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Dirección"
                                                name="address"
                                                value={address}
                                                onChange={handleInputChange}
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Número"
                                                name="number"
                                                value={number}
                                                onChange={handleInputChange}
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Ubicación"
                                                name="location"
                                                value={location}
                                                onChange={handleInputChange}
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Provincia"
                                                name="province"
                                                value={province}
                                                onChange={handleInputChange}
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextareaAutosize
                                                style={{ width: '100%', padding: '8px' }}
                                                name="description"
                                                placeholder="Descripción"
                                                value={description}
                                                onChange={handleInputChange}
                                                minRows={3}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="IP"
                                                name="ip"
                                                value={ip}
                                                onChange={handleInputChange}
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Puerto TCP"
                                                name="tcp_port"
                                                value={tcp_port}
                                                onChange={handleInputChange}
                                                size="small"
                                                type="number"
                                                fullWidth
                                                inputProps={{ min: 1, max: 65535 }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Número de Gateway"
                                                name="gateway_number"
                                                value={gateway_number}
                                                onChange={handleInputChange}
                                                size="small"
                                                type="number"
                                                fullWidth
                                                inputProps={{
                                                    min: 0,
                                                    max: activeContract?.number_of_gateways
                                                        ? activeContract?.number_of_gateways - 1
                                                        : 0,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Latitud"
                                                name="latitude"
                                                value={latitude}
                                                onChange={handleInputChange}
                                                size="small"
                                                type="number"
                                                fullWidth
                                                inputProps={{ min: -90, max: 90, step: 0.000001 }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Longitud"
                                                name="longitude"
                                                value={longitude}
                                                onChange={handleInputChange}
                                                size="small"
                                                type="number"
                                                fullWidth
                                                inputProps={{ min: -180, max: 180, step: 0.000001 }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MapContainer center={[40.4, -3.7]} zoom={6} style={{ height: 400, width: '100%' }}>
                                                <GeoSearch />
                                                <TileLayer
                                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />
                                                <LocationMarker />
                                            </MapContainer>
                                        </Grid>
                                    </Grid>
                                </CardContent>

                                <CardActions>
                                    {msgError && (
                                        <FormHelperText error>{msgError}</FormHelperText>
                                    )}
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        startIcon={<SaveIcon />}
                                        type="submit"
                                        disabled={disabledSaveButton}
                                    >
                                        Guardar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        startIcon={<ReplayIcon />}
                                        type="reset"
                                    >
                                        Reset
                                    </Button>
                                </CardActions>
                            </form>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};



