
import React, { useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  Box,
  Checkbox,
  FormControlLabel,
  Divider,
  Container,
  Tooltip,
  IconButton,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { useForm } from '../../../hooks/useForm';
import Swal from 'sweetalert2';
import validator from 'validator';
import { useSelector, useDispatch } from 'react-redux';
import { ContractsList } from '../../contracts/ContractsList/ContractsList';
import { CustomTitle } from '../../common/CustomTitle/CustomTitle';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { startUsersCreate } from '../../../reducers/users/usersActions';

export const UserCreateScreen = () => {
  const [formValues, handleInputChange, reset] = useForm({
    name: '',
    password: ' ', // Campo no modificable.
    company: '',
    email: '',
    emailConfirmation: '', // Campo adicional para confirmar el correo.
    terms_of_use: false,
    access_api: true,
    last_ip: '',
    sfa_secret: '',
  });

  const [selectedContracts, setSelectedContracts] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const contracts = useSelector((state) => state.contracts.contracts);
  const contractsLoaded = contracts && contracts.length > 0;

  const handleSelectedContractsChange = (contracts) => {
    const updatedContracts = contracts.map((contract) => {
      const existing = selectedContracts.find((c) => c.id === contract.id);
      return (
        existing || {
          id: contract.id,
          name: contract.name,
          permissions: 'supervisor',
          type: 'P2P/CM',
          user_id: 0,
          subcontract_id: '',
          boss: 0,
          operator: 0,
          person_in_charge: 0,
        }
      );
    });
    setSelectedContracts(updatedContracts);
  };

  const handlePermissionChange = (contractId, value) => {
    setSelectedContracts((prevContracts) =>
      prevContracts.map((contract) =>
        contract.id === contractId ? { ...contract, permissions: value } : contract
      )
    );
  };

  const handleTypeChange = (contractId, value) => {
    setSelectedContracts((prevContracts) =>
      prevContracts.map((contract) =>
        contract.id === contractId ? { ...contract, type: value } : contract
      )
    );
  };

  const prepareUserData = () => {
    const { emailConfirmation, ...preparedData } = {
      ...formValues,
      user_name: formValues.emailConfirmation, // Asignar el valor de emailConfirmation a user_name.
      terms_of_use: 0,
      access_api: formValues.access_api ? 1 : 0,
    };
    return preparedData;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar que el correo electrónico tenga un formato válido.
    if (!validator.isEmail(formValues.email)) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'El correo electrónico no es válido',
        confirmButtonColor: '#d33',
      });
      return;
    }

    // Validar que los correos coincidan.
    if (formValues.email !== formValues.emailConfirmation) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Los correos electrónicos no coinciden',
        confirmButtonColor: '#d33',
      });
      return;
    }

    const userData = prepareUserData();

    // Generar información para mostrar en el cartel de confirmación.
    const contractsInfo = selectedContracts.map(
      (contract) =>
        `<b>Contrato:</b> ${contract.name}<br/>
         <b>Permisos:</b> ${contract.permissions}<br/>
         <b>Tipo:</b> ${contract.type}<br/>`
    ).join('<hr/>');

    const confirmationText = `
      <b>Nombre de usuario:</b> ${userData.name}<br/>
      <b>Empresa:</b> ${userData.company}<br/>
      <b>Email:</b> ${userData.email}<br/><br/>
      <b>Contratos asignados:</b><br/>${contractsInfo}
    `;

    // Mostrar cartel de confirmación.
    const { isConfirmed } = await Swal.fire({
      title: 'Confirmar creación del usuario',
      html: confirmationText,
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    });

    if (!isConfirmed) {
      return; // Cancelar la creación si no se confirma.
    }

    try {
      Swal.fire({
        title: 'Creando usuario...',
        text: 'Por favor espera',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      // Llamar al action para crear usuario y asignar contratos.
      await dispatch(startUsersCreate({ user: userData, contracts: selectedContracts }));
      /*
            Swal.fire({
              icon: 'success',
              title: 'Éxito',
              text: 'El usuario y sus permisos fueron creados correctamente',
              confirmButtonColor: '#3085d6',
            });
      */
      reset(); // Reiniciar el formulario.
      navigate('/users-management');
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Hubo un problema: ${error.message}`,
        confirmButtonColor: '#d33',
      });
    }
  };
  /*
    const handleDebug = () => {
      const debugUserData = prepareUserData();
    };
  */
  return (
    <Container maxWidth={false} sx={{ padding: 2 }}>
      <Grid xs={12}>
        <CustomTitle title="Crear usuario" icon={<GroupAddIcon />} advanced={true} />
      </Grid>
      <Grid>
        <Divider sx={{ padding: 2 }} />
      </Grid>
      <Box sx={{ maxWidth: 900, mx: 'auto', p: 3 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="name"
                label="Nombre de Usuario"
                value={formValues.name}
                onChange={handleInputChange}
                required
              />
            </Grid>


            <Grid item xs={12}>
              <TextField
                fullWidth
                name="company"
                label="Empresa"
                value={formValues.company}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="email"
                label="Correo Electrónico"
                value={formValues.email}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="emailConfirmation"
                label="Confirmar Correo Electrónico"
                value={formValues.emailConfirmation}
                onChange={handleInputChange}
                required
              />
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formValues.access_api}
                    onChange={handleInputChange}
                    name="access_api"
                  />
                }
                label="Acceso API"
              />
              <Tooltip
                title="Marca la casilla si el nuevo usuario debe tener acceso a las APIs de Tellink"
                arrow
              >
                <IconButton>
                  <HelpCenterIcon style={{ color: 'gold' }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Box sx={{ my: 2 }}>
            <Divider />
          </Box>

          <Box>
            <ContractsList onSelectedContractsChange={handleSelectedContractsChange} />
          </Box>

          {selectedContracts?.map((contract) => (
            <Box key={contract.id} sx={{ my: 3, p: 2, border: '1px solid #ccc', borderRadius: '8px' }}>
              <Typography variant="h5">Configuración de usuario en el contrato {contract.name}</Typography>
              <FormControl sx={{ my: 2 }}>
                <FormLabel>Permisos</FormLabel>
                <RadioGroup
                  row
                  value={contract.permissions}
                  onChange={(e) => handlePermissionChange(contract.id, e.target.value)}
                >
                  <FormControlLabel value="supervisor" control={<Radio />} label="Supervisor" />
                  <FormControlLabel value="manager" control={<Radio />} label="Gestor" />
                  <FormControlLabel value="administrator" control={<Radio />} label="Administrador" />
                  <FormControlLabel value="super_administrator" control={<Radio />} label="Super Administrador" />
                </RadioGroup>
              </FormControl>

              <FormControl sx={{ my: 2 }}>
                <FormLabel>Tipo</FormLabel>
                <RadioGroup
                  row
                  value={contract.type}
                  onChange={(e) => handleTypeChange(contract.id, e.target.value)}
                >
                  <FormControlLabel value="CM" control={<Radio />} label="CM" />
                  <FormControlLabel value="P2P" control={<Radio />} label="P2P" />
                  <FormControlLabel value="P2P/CM" control={<Radio />} label="P2P/CM" />
                </RadioGroup>
              </FormControl>
            </Box>
          ))}
        </form>

        <Grid item xs={12} sx={{ my: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!contractsLoaded}
          >
            {!contractsLoaded ? <CircularProgress size={24} /> : 'Crear Usuario'}
          </Button>
        </Grid>

        {
        /*<Grid item xs={12} sx={{ my: 2 }}>
          <Button variant="outlined" color="secondary" onClick={handleDebug}>
            Depurar Datos
          </Button>
        </Grid>
        */}
      </Box>
    </Container>
  );
};









